import { List, Spinner } from '@telegram-apps/telegram-ui';
import { useEffect, useMemo, useState } from 'react';
import { getScheduleItem } from '../../api/schedule';
import { QuizSchedule } from '../../api/schedule/types';
import { ScheduleHeader } from '../SchedulePage/components/Schedule/components/ScheduleHeader';
import { ScheduleItem } from '../SchedulePage/components/Schedule/components/ScheduleItem';
import { BotWelcome } from './BotWelcome';
import { PageBackground } from './PageBackground';
import { PageHelmet } from './PageHelmet';
import { QuizError } from './QuizError';

import styles from './index.module.css';

export const ScheduleGamePage = () => {
  const [quiz, setQuiz] = useState<QuizSchedule>();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const slug = useMemo(() => {
    const pathname = window?.location?.pathname;
    if (typeof pathname !== 'string' || !pathname) {
      return;
    }
    return pathname.split('/').filter(Boolean).slice(1).join('');
  }, []);

  useEffect(() => {
    if (!slug) {
      setIsError(true);
      return;
    }
    const getData = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const r = await getScheduleItem({ slug });
        setQuiz(r);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };
    getData();
  }, [slug]);

  if (isLoading) {
    return (
      <PageBackground>
        <List className={styles.centerPadding}>
          <ScheduleHeader city="" />
          <Spinner size="l" />
        </List>
      </PageBackground>
    );
  }

  if (isError || !quiz) {
    return (
      <PageBackground>
        <List className={styles.centerPadding}>
          <ScheduleHeader city="" />
          <QuizError />
        </List>
      </PageBackground>
    );
  }

  return (
    <PageBackground>
      <PageHelmet quiz={quiz} />
      <List className={styles.centerPadding}>
        <ScheduleHeader city="" />
        <ScheduleItem item={quiz} />
        <BotWelcome />
      </List>
    </PageBackground>
  );
};
