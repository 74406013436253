import request from 'umi-request';
import { SCHEDULE_GRABBER_API } from '../../const/env';
import {
  AuthType,
  GetScheduleItemParams,
  GetScheduleListQuery,
  QuizSchedule,
} from './types';

export const getSchedule = async (
  initDataRaw = '',
  params: GetScheduleListQuery
) => {
  const authorization = `${AuthType.TMA} ${initDataRaw}`;
  const r = await request<QuizSchedule[]>(`${SCHEDULE_GRABBER_API}/schedule`, {
    method: 'GET',
    headers: {
      authorization,
    },
    params,
  });
  return r;
};

export const getScheduleItem = async (params: GetScheduleItemParams) => {
  const { slug } = params;
  const r = await request<QuizSchedule>(
    `${SCHEDULE_GRABBER_API}/schedule/${slug}`,
    {
      method: 'GET',
    }
  );
  return r;
};
