import moment from 'moment';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { QuizSchedule } from 'src/api/schedule/types';

type Props = {
  quiz: QuizSchedule;
};

export const PageHelmet: FC<Props> = ({ quiz }) => {
  const dateStr = quiz
    ? moment(quiz.scheduledAt).locale('ru').format('D MMMM (dddd), в HH:mm')
    : undefined;

  return (
    <Helmet htmlAttributes={{ translate: 'no' }}>
      <html lang="ru" prefix="og: http://ogp.me/ns#" />
      <meta property="og:type" content="website" />
      <title>{quiz.title}</title>
      <meta property="og:title" content={`${quiz.title} · ${dateStr}`} />
      {quiz.imageUrl && <meta property="og:image" content={quiz.imageUrl} />}
      {quiz.description && (
        <meta property="description" content={quiz.description} />
      )}
    </Helmet>
  );
};
