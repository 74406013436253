import { Button, Card, Text } from '@telegram-apps/telegram-ui';

import styles from './index.module.css';
import { Space } from 'src/components/Space';

export const BotWelcome = () => {
  return (
    <Card className={styles.card}>
      <div className={styles.content}>
        <Space direction="vertical">
          <Text>
            Подключайся к телеграм-боту, смотри расписание и получай уведомления
            о предстоящих играх.
          </Text>
          <Button
            mode="bezeled"
            style={{ marginTop: 12 }}
            onClick={() => window.open('https://t.me/kogdaquizbot')}
          >
            Подключиться
          </Button>
        </Space>
      </div>
    </Card>
  );
};
