import { retrieveLaunchParams } from '@telegram-apps/sdk-react';
import { Button, List, Spinner, Text } from '@telegram-apps/telegram-ui';
import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { getSchedule } from 'src/api/schedule';
import { QuizSchedule } from 'src/api/schedule/types';
import { Space } from 'src/components/Space';
import { ScheduleHeader } from './components/ScheduleHeader';
import { ScheduleItem } from './components/ScheduleItem';

import styles from './index.module.css';

export const Schedule = () => {
  const { initDataRaw } = retrieveLaunchParams();

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState<QuizSchedule[]>();

  const getData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      // TODO: При реализации выбора городов, устанавливать тут город
      const r = await getSchedule(initDataRaw, { city: 'Киров' });
      setList(sortBy(r, (v) => v.scheduledAt));
    } catch (error) {
      setIsError(true);
      // TODO
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRefresh = () => {
    getData();
  };

  if (isLoading) {
    return (
      <Space direction="vertical" className={styles.centerPadding}>
        <ScheduleHeader />
        <Spinner size="s" />
      </Space>
    );
  }

  if (isError) {
    return (
      <Space direction="vertical" className={styles.centerPadding}>
        <ScheduleHeader />
        <Text>Не удалось загрузить расписание игр, попробуйте зайти позже</Text>
        <Button onClick={handleRefresh}>Попробовать еще раз</Button>
      </Space>
    );
  }

  if (list === undefined) {
    return null;
  }

  return (
    <List className={styles.centerPadding}>
      <ScheduleHeader />
      {list.map((item) => (
        <ScheduleItem key={item.id} item={item} />
      ))}
    </List>
  );
};
