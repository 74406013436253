export type QuizSchedule = {
  quizCode: string;
  quizTitle: string;
  id: string;
  scheduledAt: string;
  title: string;
  slug: string;
  description?: string;
  cost?: number;
  registerLink?: string;
  place?: string;
  imageUrl?: string;
};

export enum AuthType {
  TMA = 'tma',
  API_KEY = 'api-key',
}

export type GetScheduleListQuery = {
  city: string;
};

export type GetScheduleListResponse = QuizSchedule[];

export type ErrorResponse = {
  code: string;
  message?: string;
};

export type GetScheduleItemParams = { slug: string };

export type GetScheduleItemResponse = QuizSchedule;
