import { Text } from '@telegram-apps/telegram-ui';

import logo from 'src/assets/logo.png';

import styles from './index.module.css';
import { FC } from 'react';

type Props = {
  city?: string;
};

export const ScheduleHeader: FC<Props> = ({ city = 'г. Киров' }) => {
  return (
    <div className={styles.header}>
      <img
        alt="Лого"
        src={logo}
        style={{
          display: 'block',
          objectFit: 'cover',
          height: '16vh',
          width: '16vh',
        }}
      />
      {city && <Text className={styles.city}>г. Киров</Text>}
    </div>
  );
};
