import {
  Button,
  Card,
  Chip,
  Headline,
  Text,
  Title,
} from '@telegram-apps/telegram-ui';
import moment from 'moment';
import { useMemo } from 'react';
import { BiSolidGame, BiSolidMap } from 'react-icons/bi';
import { QuizSchedule } from 'src/api/schedule/types';
import quiz_placeholder from 'src/assets/quiz-placeholder.jpg';
import { Space } from 'src/components/Space';
import { CoverImage } from './components/CoverImage';

import {
  OKIcon,
  OKShareButton,
  TelegramIcon,
  TelegramShareButton,
  ViberIcon,
  ViberShareButton,
  VKIcon,
  VKShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

import styles from './index.module.css';

type ChipEntity = {
  label: string;
  backgroundColor: string;
  color: string;
  icon: React.ReactNode;
};

type Props = {
  item: QuizSchedule;
};

export const ScheduleItem: React.FC<Props> = ({ item }) => {
  const {
    title,
    description,
    scheduledAt,
    quizTitle,
    place,
    cost,
    imageUrl,
    registerLink,
    slug,
  } = item;

  const scheduledAtRender = useMemo(() => {
    const dateStr = moment(scheduledAt)
      .locale('ru')
      .format('D MMMM (dddd), в HH:mm');
    if (moment().isSame(scheduledAt, 'day')) {
      return {
        date: `Сегодня, ${dateStr}`,
        className: styles.scheduledAtToday,
      };
    }
    if (moment().add(1, 'day').isSame(scheduledAt, 'day')) {
      return {
        date: `Завтра, ${dateStr}`,
        className: styles.scheduledAtTomorrow,
      };
    }
    return {
      date: dateStr,
      className: styles.scheduledAt,
    };
  }, [scheduledAt]);

  const chips = useMemo(() => {
    const backgroundColor = '#FF000090';
    const color = '#000';
    const r: ChipEntity[] = [];
    if (quizTitle) {
      r.push({
        label: quizTitle,
        backgroundColor,
        color,
        icon: <BiSolidGame size={20} />,
      });
    }
    if (place) {
      r.push({
        label: place,
        backgroundColor,
        color,
        icon: <BiSolidMap size={20} />,
      });
    }
    return r;
  }, [quizTitle, place]);

  const gameShareUrl = `${window.location.origin}/game/${slug}/`;

  return (
    <Card className={styles.card}>
      <div className={styles.imageAndTagsWrapper}>
        <CoverImage url={imageUrl ?? quiz_placeholder} height="30vh" />
      </div>
      <div className={styles.content}>
        <Space direction="vertical" size={8}>
          <Title level="2" weight="1" className={styles.title}>
            {title}
          </Title>
          <Headline className={scheduledAtRender.className}>
            {scheduledAtRender.date}
          </Headline>
          {description && <Text>{description}</Text>}
          {chips.length > 0 && (
            <div className={styles.chips}>
              {chips.map(({ label, icon }, idx) => (
                <Chip
                  key={idx}
                  mode="elevated"
                  readOnly
                  className={styles.chip}
                >
                  {icon}
                  {label}
                </Chip>
              ))}
            </div>
          )}
          <Space className={styles.shareBlock}>
            {registerLink && (
              <Space>
                <Button
                  size="l"
                  style={{ marginTop: 12 }}
                  onClick={() => window.open(registerLink)}
                >
                  Регистрация
                </Button>
                {cost && (
                  <Text weight="3" className={styles.cost}>
                    {cost} ₽
                  </Text>
                )}
              </Space>
            )}
            <Space className={styles.share}>
              <TelegramShareButton title={title} url={gameShareUrl}>
                <TelegramIcon size={32} round />
              </TelegramShareButton>
              <VKShareButton title={title} url={gameShareUrl} image={imageUrl}>
                <VKIcon size={32} round />
              </VKShareButton>
              <WhatsappShareButton title={title} url={gameShareUrl}>
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              <ViberShareButton title={title} url={gameShareUrl}>
                <ViberIcon size={32} round />
              </ViberShareButton>
              <OKShareButton title={title} url={gameShareUrl} image={imageUrl}>
                <OKIcon size={32} round />
              </OKShareButton>
            </Space>
          </Space>
        </Space>
      </div>
    </Card>
  );
};
