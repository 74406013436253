import { FC, PropsWithChildren } from 'react';

import background from 'src/assets/quiz-placeholder.jpg';

import styles from './index.module.css';

export const PageBackground: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <div
        className={styles.background}
        style={{ backgroundImage: `url(${background})` }}
      />
      <div className={styles.content}>{children}</div>
    </>
  );
};
