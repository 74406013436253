import { PropsWithChildren } from 'react';

type Props = {
  size?: 'small' | 'medium' | 'large' | number;
  direction?: 'vertical' | 'horizontal';
  style?: React.CSSProperties;
  className?: string;
};

export const Space: React.FC<PropsWithChildren<Props>> = ({
  size = 'medium',
  direction = 'horizontal',
  style,
  className,
  children,
}) => {
  const gap =
    size === 'small'
      ? 4
      : size === 'large'
      ? 24
      : size === 'medium'
      ? 16
      : size;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: direction === 'vertical' ? 'column' : 'row',
        alignItems: 'baseline',
        gap,
        ...style,
      }}
      className={className}
    >
      {children}
    </div>
  );
};
