import { useInitData } from '@telegram-apps/sdk-react';
import { PropsWithChildren } from 'react';

export const AppAuth: React.FC<PropsWithChildren> = ({ children }) => {
  const initData = useInitData();
  if (!initData) {
    return null;
  }
  const { user } = initData;
  if (!user) {
    return null;
  }
  return <>{children}</>;
};
